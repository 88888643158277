import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux'
import { configureStore, getDefaultMiddleware, EnhancedStore } from '@reduxjs/toolkit'
import { RootReducer } from './reducers'
import logger from 'redux-logger'

const setupStore = (): EnhancedStore => {
	const middlewares = [...getDefaultMiddleware()]
	
	if (process.env.NODE_ENV==='development') {
		middlewares.push(logger)
	}
	
	return configureStore({
		reducer: RootReducer,
		middleware: middlewares,
		devTools: true
	})
}

export const store = setupStore()

export type RootState = ReturnType<typeof store.getState>

export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector
