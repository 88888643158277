import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type StickyState = {
	rootId: number;
	items: any;
	isShow: boolean;
}

export const initialState: StickyState = {
	rootId: null,
	items: null,
	isShow: false
}

const StickyModule = createSlice({
	name: 'sticky',
	initialState,
	reducers: {
		INIT: (state, action: PayloadAction<any>) => {
			let args = Object.assign({}, state)
			if (action.payload.rootId) {
				args.rootId = action.payload.rootId
			}
			if (action.payload.items) {
				args.items = action.payload.items
			}
			if (action.payload.isShow) {
				args.isShow = action.payload.isShow
			}
			return args
		},
		SAVE: (state, action: PayloadAction<any>) => {
			let args = Object.assign({}, state)
			args = Object.assign(args, action.payload)
			return args
		}
	}
})

export const { INIT, SAVE } = StickyModule.actions

export default StickyModule