import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type NotifyState = {
	rootId: number;
	items: any;
	isWhole: boolean;
	isShow: boolean;
}

export const initialState: NotifyState = {
	rootId: null,
	items: null,
	isWhole: true,
	isShow: false
}

const NotifyModule = createSlice({
	name: 'notify',
	initialState,
	reducers: {
		INIT: (state, action: PayloadAction<any>) => {
			let args = Object.assign({}, state)
			if (action.payload.rootId) {
				args.rootId = action.payload.rootId
			}
			if (action.payload.items) {
				args.items = action.payload.items
			}
			if (action.payload.isWhole) {
				args.isWhole = action.payload.isWhole
			}
			if (action.payload.isShow) {
				args.isShow = action.payload.isShow
			}
			return args
		},
		SAVE: (state, action: PayloadAction<any>) => {
			let args = Object.assign({}, state)
			args = Object.assign(args, action.payload)
			return args
		}
	}
})

export const { INIT, SAVE } = NotifyModule.actions

export default NotifyModule