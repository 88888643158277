import _ from 'lodash'
import moment from 'moment'

export const findIndex = (element, args) => {
	let index = null
	args.forEach(arg => {
		index = args.findIndex(arg => arg === element)
	})
	return index
}

export const findItem = (key, args, field = 'id') => {
	const item = args.filter(i => `${i[field]}`===`${key}`)[0]
	return item
}

export const findList = (key, args, field = 'parent_id') => {
	if (!args) {
		return null
	}
	const lists = args.filter(i => `${i[field]}`===`${key}`)
	return lists
}

export const getAttributes = node => {
	let attr = {}
	Object.keys(node).forEach(key => {
		const match = node[key].name.match(/^data\-(\S+)/)
		if (match) {
			let keyname = snakeToCamel(match[1])
			let value = node[key].value
			if (value == 'true') {
				value = true
			} else if (value == 'false') {
				value = false
			}
			attr[keyname] = value
		}
	})
	return attr
}

export const getDurationFromElement = element => {
	if (!element) {
		return 0
	}

	const cssStyleDeclaration = getComputedStyle(element, null)
	let duration = cssStyleDeclaration.getPropertyValue('transition-duration')
	let delay = cssStyleDeclaration.getPropertyValue('transition-delay')
	const floatDuration = parseFloat(duration)
	const floatDelay = parseFloat(delay)

	if (!floatDuration && !floatDelay) {
		return 0
	}

	duration = duration.split(',')[0]
	delay = delay.split(',')[0]
	return (parseFloat(duration) + parseFloat(delay)) * 1000
}

export const createPassword = (length = 8) => {
	const str = '0123456789abcdefghijklmnopqrstuvwzABCDEFGHIJKLMNOPQRSTUVWXYZ-_.:;#$%@!?=+*'
	let p = ''
	for (let i=0; i<length; i++) {
		p += str.charAt(Math.floor(Math.random() * str.length))
	}
	return p
}

export const dateAgo = datetime => {
	const from = moment(datetime)
	const diff = moment().diff(datetime, 'day')
	let date
	
	if (diff <= 7) {
		if (diff > 0) {
			date = diff + '日前'
		} else {
			const minute = moment().diff(datetime, 'minute')
			if (minute >= 60) {
				date = Math.floor(minute/60) + '時間前'
			} else if (minute >= 3) {
				date = minute + '分前'
			} else {
				date = 'たった今'
			}
		}
	} else {
		date = from.format('YYYY/MM/DD h:mm')
	}
	return date
}

export const snakeToCamel = str => {
	return str.replace(/-./g, s => {
		return s.charAt(1).toUpperCase()
	})
}

export const camelToSnake = str => {
	return str.replace(/([A-Z])/g, s => {
		return '_' + s.charAt(0).toLowerCase()
	})
}

export const promiseInterval = interval => {
	return new Promise(resolve => setTimeout(resolve, interval))
}