import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type BoardState = {
	rootId: number;
	title: string;
	items: any;
	opened: number;
}

export const initialState: BoardState = {
	rootId: null,
	title: null,
	items: null,
	opened: null
}

const BoardModule = createSlice({
	name: 'board',
	initialState,
	reducers: {
		INIT: (state, action: PayloadAction<any>) => {
			let args = Object.assign({}, state)
			if (action.payload.id) {
				args.rootId = action.payload.id
			}
			if (action.payload.title) {
				args.title = action.payload.title
			}
			if (action.payload.items) {
				args.items = action.payload.items
			}
			if (action.payload.opened) {
				args.opened = action.payload.opened
			}
			return args
		},
		SAVE: (state, action: PayloadAction<any>) => {
			let args = Object.assign({}, state)
			args = Object.assign(args, action.payload)
			return args
		}
	}
})

export const { INIT, SAVE } = BoardModule.actions

export default BoardModule