import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ReportState = {
	items: any;
	sum: number;
	max: number;
	breaks: number;
	current: any;
}

export const initialState: ReportState = {
	items: null,
	sum: null,
	max: null,
	breaks: null,
	current: null
}

const ReportModule = createSlice({
	name: 'report',
	initialState,
	reducers: {
		INIT: (state, action: PayloadAction<any>) => {
			let args = Object.assign({}, state)
			if (action.payload.items) {
				args.items = action.payload.items
			}
			if (action.payload.sum) {
				args.sum = action.payload.sum
			}
			if (action.payload.max) {
				args.max = action.payload.max
			}
			if (action.payload.breaks) {
				args.breaks = action.payload.breaks
			}
			if (action.payload.current) {
				args.current = action.payload.current
			}
			return args
		},
		SAVE: (state, action: PayloadAction<any>) => {
			let args = Object.assign({}, state)
			args = Object.assign(args, action.payload)
			return args
		}
	}
})

export const { INIT, SAVE } = ReportModule.actions

export default ReportModule