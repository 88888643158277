import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { store } from './modules/store'
import { getAttributes } from './modules/util'
import './scss/plugins.scss'

if (document.getElementById('notify') != null) {
	import('./components/notify').then(({default: Notify}) => {
		ReactDOM.render(<Provider store={store}><Notify /></Provider>, document.getElementById('notify'))
	}).catch(e => {
		console.log(e)
	})
}

if (document.getElementById('sticky') != null) {
	import('./components/sticky').then(({default: Sticky}) => {
		ReactDOM.render(<Provider store={store}><Sticky /></Provider>, document.getElementById('sticky'))
	}).catch(e => {
		console.log(e)
	})
}

if (document.getElementById('report') != null) {
	import('./components/report').then(({default: Report}) => {
		ReactDOM.render(<Provider store={store}><Report /></Provider>, document.getElementById('report'))
	}).catch(e => {
		console.log(e)
	})
}

if (document.querySelectorAll('[data-select]')) {
	import('./components/select').then(({default: Select}) => {
		const selectors = document.querySelectorAll('[data-select]')
		selectors.forEach(selector => {
			const props = getAttributes(selector.attributes)
			ReactDOM.render(<Select {...props} />, selector)
		})
	}).catch(e => {
		console.log(e)
	})
}